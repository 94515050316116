<template>
  <MainForm
    type="edit"
    v-on:submit="submit" />
</template>
<script>
import MainForm from './form'
// import { mapState } from 'vuex'

export default {
  name: 'postEdit',
  data() {
    return {}
  },
  // computed: {
  //   ...mapState('tag', {
  //     tags: state => state.data,
  //   }),
  // },
  created() {
    const vm = this
    vm.$store.dispatch('post/FETCH_ONE', vm.$route.params.id)
  },
  beforeRouteLeave(to, from, next) {
    if (from.path.includes('/posts/edit/')) {
      // const conf = window.confirm('Do you really want to leave? you have unsaved post!')
      // if (conf) {
      // @ts-ignore
      if (this.$route.params && this.$route.params.id) {
        window.deletedoc(parseInt(this.$route.params.id))
      }
      // @ts-ignore
      // window.unsubscribe()
      next()
      // } else {
      //   next(false)
      // }
    // } else {
    //   next()
    }
  },
  methods: {
    submit() {
      const vm = this
      this.$nprogress.start()
      vm.$store.dispatch('post/UPDATE', vm.$route.params.id).then((res) => {
        this.$nprogress.done()
        vm.$notification.success({
          message: 'Post Updated',
          description: 'Successfully updated.',
        })
        // vm.$router.push({ path: '/posts' })
      }).catch(err => {
        this.$notification.error({
          message: 'Edit post error',
          description: err.message,
        })
        this.$nprogress.done()
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
